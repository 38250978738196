import React from "react";

function ContentBox(){
    return(<div>

<div class="seven">
  <h1>About janakpurinn</h1>
</div>

        <div class="contentboxx">
  <section class="box">
    <h1></h1>
    <p class="fancy">The Janakpur Inn (Hotel & Apartments) is a boutique hotel situated in a quiet and peaceful location in Ramanand Chowk-08, Dhanusha, Nepal. Just a fifteen-minute drive from the Janakpur airport will get you to us. Janakpur Inn provides everything Janakpur has to offer at a reasonable price.

At our boutique hotel, we offer a perfect blend of comfort, luxury, and tranquility, ensuring a delightful and unforgettable experience for our guests. As you step in, you're welcomed into the land of the birthplace of Goddess Sita.

Janakpur Inn boasts a tasteful interior design, excellent amenities, and first-rate comforts that epitomize the essence of a boutique hotel experience. Our cozy rooms provide spacious living spaces, creating a sanctuary of calm and tranquility, all supported effectively by our kind and attentive hospitality.

Conveniently located near neighborhood shops and restaurants, janakpurinn offers all the amenities guests desire, and it serves as a perfect base to explore the city's top attractions. Join us at Janakpur Inn, where you'll discover a harmonious fusion of modern luxury and ancient spirituality in this sacred land. Welcome to Janakpur, the land of Goddess Sita's birthplace.</p>
  </section>
</div>
    </div>);

}


export default ContentBox;