import React,{useState,useEffect,useRef,Component} from 'react';
import emailjs from '@emailjs/browser';
import { useLocation } from 'react-router-dom'

import swal from '@sweetalert/with-react'

import ReCAPTCHA from "react-google-recaptcha";
import { setTextRange } from 'typescript';

import axios from 'axios';


function Confirm(){

  const form = useRef();

const [phone,setphone]=useState("");
const [email,setemail]=useState("");
const [name,setName]=useState("");

const [room,setRoom]=useState([]);
const [fin,setFin]=useState(""); 
const [tax,setTax]=useState(""); 
const [sc,setSc]=useState(""); 
const [total,setTotal]=useState(""); 
// const[verified,setVerified]=useState(false);
const[buttonClick,setButtonClick]=useState('');

const [selectedRoom,setSelectRoom]=useState([]);
const [quantity,setQuantity]=useState([]);
// When initializing state, retrieve values from localStorage or use default values


// Initialize state with retrieved values or default values
const [cin, setcin] = useState('');
const [cout, setcout] = useState('');




var selectedRoomArr=[];
var quantityRoomArr=[];


const location = useLocation();
const data = location.state;


// function handle(){
//   setVerified(true)
// }

const handleSubmit = async(e) => {
  e.preventDefault();



  // if(buttonClick=="khalti"){
  // console.log(total);  
  //  const response = await axios.post('http://localhost:3001/back', {selectedRoom,quantity,name,total,fin,cin,cout,phone,email});
   

  //  console.log("done");
  //  const Payurl = response.data.data.payment_url;
  //  window.location.href=Payurl;
  //  swal("Your booking request has been received", "You will be contacted shortly", "success").then((confirmed) => {
  //   // if(confirmed){
  //   //   window.location.href="http://janakpurinn.com";
  //   // }
  //   }
     
  //   )
// }

if(buttonClick == "nonPay"){
   var templateParams = {
    name: name,
    email: email,
    cin:cin,
    cout:cout,
    phone:phone,  
    fin:fin,
    total:total,
    selectedRoom:selectedRoom,
    quantity:quantity
  };
  console.log(selectedRoom)
  e.preventDefault();

  // emailjs.sendForm('service_43xmhgt', 'template_srzismx', form.current, 'p87AIKhlpzHzrnl46')
  //   .then((result) => {
  //       console.log(result.text);
  //   }, (error) => {
  //       console.log(error.text);
  //   });
  emailjs.send('service_43xmhgt', 'template_srzismx', templateParams, 'p87AIKhlpzHzrnl46') 
    swal("Your booking request has been received", "You will be contacted shortly", "success").then((confirmed) => {
     if(confirmed){
      window.location.href="https://janakpurinn.com";
     }
    }    
    )
    
};

}




var sum = 0;
  useEffect(() => {

  if (data.data.room) {
    setRoom(data.data.room);
  }

  if (data.cout) {
    setcin(data.cin);
    setcout(data.cout);
  } 

  if (data.phone) {
    setphone(data.phone);
  }

  if (data.email) {
    setemail(data.email);
  }

  if (data.fname && data.lname) {
    setName(data.fname + " " + data.lname);
  }

  try {
    // To set two dates to two variables
    var date1 = new Date(cin);
    var date2 = new Date(cout);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    
    if (!isNaN(Difference_In_Days)) {
      sum = sum * Difference_In_Days;
      setFin(sum);
      var sc = 0.10 * sum;
      setSc(sc);
      var tax = (sum + sc) * 0.13;
      setTax(tax);
      var total = sum + sc + tax;
      setTotal(total);
    } else {
      console.error("Invalid date format");
    }
  } catch (error) {
    console.error("Error calculating days difference:", error);
  }
});



    
  




  return(<div>

<div class="containerConfirm">
  <div class="row">
    <div class="col-md-8 col-sm-6 col-xs-12 col-md-pull-6 col-sm-pull-6">
                      <div class="panel panel-default">
                          <div class="panel-heading text-center"><h4><strong>Room confirmation</strong></h4></div>
                          <div class="panel-body">
                          <table class="table table-light">
    <thead>
      <tr>
        <th>Product</th>
        <th>Price(Nrs)</th>
        <th>Quantity</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>

   

    {room.map((d,a) => {
       
        
        return (
                    d.map((r,i)=>{

                     
                      
                        if(a==1 && r>0){
                            sum = sum + room[2][i]*room[1][i];
                            selectedRoomArr.push(room[0][i]);
                            quantityRoomArr.push(room[1][i]);
                            return(
                        <tr>
                    <td data-label="ROOM">
                        <p id="prodName">{room[0][i]}<br/></p>
                    </td>
                    <td data-label="RATE" class="rate">{room[2][i]}</td>
                    <td data-label="QUANTITY">
                        <input type="number" defaultValue={room[1][i]} class="quantity" min="1" max="5" disabled />

                        
                    </td>
                    <td data-label="TOTAL" class="itemTotal">{room[2][i] * room[1][i]}  </td>
                    
                </tr> 
                      )  }
                      
                
                })
                )
                
    }
    )
    }
    
                   
  </tbody>
  </table>
    </div>
    </div>
      
    <div class="panel-heading text-center">
                        <h4 style={{padding:"30px 0 20px 0"}}><strong>Details confirmation</strong></h4>
                    </div>

<section id="address-form">


<form id='formFinal' ref={form} onSubmit={handleSubmit}> 
  <div class="form-row">
        <div class="form-group col-md-6">
      <label for="inputname">Name</label>
      <input type="text" class="form-control" id="inputname" required defaultValue={name}  name="name" readOnly  />
    </div>
    <div class="form-group col-md-6">
      <label for="inputEmail4">Email</label>
      <input type="email" class="form-control" id="inputEmail4" required defaultValue={email}  name="email" readOnly  />
    </div>

  </div>
    <div class="form-row">
        <div class="form-group col-md-6">
      <label for="inputnumber1">Contact Number</label>
      <input type="number" class="form-control" id="inputnumber1" required defaultValue={phone}  name="phone" readOnly  />
    </div>
   

  </div>
  <div class="form-group">
    <label for="inputAddress">Check In</label>
    <input type="date" class="form-control" id="inputAddress"  name="cin" required min={new Date().toISOString().split('T')[0]} defaultValue={cin} readOnly />
  </div>
  <div class="form-group">
    <label for="inputAddress">Check Out</label>
    <input type="date" class="form-control" id="inputAddress"  name="cout" min={cin} required defaultValue={cout} readOnly  />
  </div>

  <div class="captcha" align="center">
      {/* <ReCAPTCHA 
    sitekey="6Lc4PaEiAAAAALeDaAji_7-XCprCfQB804R9_Mkv"
    onChange={handle} 
  /> */}
      </div>

</form>
 

</section>

                  </div>
                  <br></br>
                  <br></br>
                  <br></br>

    <div class="col-md-4 col-sm-6 col-xs-12 col-md-push-6 col-sm-push-6" >
               <div class="panel panel-default" >
                    <div class="panel-heading text-center">
                        <h4 style={{padding:"30px 0 20px 0"}}><strong>Booking overview</strong></h4>
                    </div>
                    <div class="panel-body">
                        <div class="col-md-12"><strong>Subtotal:</strong><div class="pull-right">रू{fin}</div></div>
                        
                        <div class="col-md-12"><strong>Service Charge(10%):</strong><div class="pull-right">रू{sc}</div></div>
                        <div class="col-md-12"><strong>Tax (13%):</strong><div class="pull-right">रू{tax}</div>

                                  <hr></hr>
                                  </div>
                                  <div class="col-md-12">
                                      <strong>Order Total</strong>
                                      <div class="pull-right">रू{total}</div>
                                      <hr></hr>
                                  </div>
                            
                                 

                          </div>
                                
{/* <button type='submit' form='formFinal' class="finalButton"  onClick={function(e){
                  setSelectRoom(selectedRoomArr);
                  setQuantity(quantityRoomArr);
                  setButtonClick("khalti")
                }}><span class="alertFor" >Fill captcha to continue</span>
               
                    </button> */}

                    <button type='submit' form='formFinal' class="finalButton2"  onClick={function(e){
                  setSelectRoom(selectedRoomArr);
                  setQuantity(quantityRoomArr);
                  setButtonClick("nonPay")
                  
                }}><span class="alertFor" >Fill captcha to continue</span>
               
</button>               
                      </div>
                     
                  </div>
  </div>
  
</div>
</div>);
}


export default Confirm;

