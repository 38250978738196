import React from "react";

function Video(){
    return ( <div className="videoDiv">

<div class="seven">
  <h1>Experience comfort</h1>
</div>
    <iframe
      className="hotelVideo"
      src="https://streamable.com/e/lsmw8o?autoplay=1&controls=1"
      allowFullScreen
      title="Video"
      autoPlay
      muted 
    />
  </div>);
}

export default Video;