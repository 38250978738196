import React from "react";

function Abt(){
    return( <div>
    <section class="about" id="about">
            <div class="container">
                <div class="heading text">
                    <h2>About
                        <span>Us</span></h2>

                      
                    <p style={{"fontSize":"17px"}}>Within our boutique hotel establishment, you'll find a range of contemporary and spacious rooms tailored to your comfort needs, offering four distinct categories: Apartments, Deluxe, Standard, and Non A/C standard rooms, each thoughtfully furnished and designed. The Janakpur Inn (Hotel & Apartments) is idyllically located in the peaceful enclave of Ramanand Chowk-08, Dhanusha, Nepal, just a convenient fifteen-minute drive from Janakpur airport. Our commitment is to provide everything Janakpur has to offer at a reasonable price. Ramanand Chowk holds deep significance for Hindus, serving as a prominent pilgrimage site linked to the revered Hindu epic, the Ramayana. At Janakpur Inn, we take pride in presenting tasteful interior designs, a wide array of amenities, and top-tier comforts, all aimed at ensuring a delightful vacation experience for our valued guests within our boutique hotel. Our rooms exude coziness and offer spacious living areas, creating a haven of peace and serenity, harmoniously complemented by our warm and hospitable staff. This intimate boutique hotel is well-equipped to cater to our guests' every need and conveniently situated near a variety of local shops and restaurants. Explore the city's most iconic attractions with ease during your stay.</p>
                
                <div class="row">
                   
                    <div class="col-lg-12">
                        <h3>We await you </h3>
                        <p style={{"fontSize":"17px"}}>Janakpur Inn Hotel awaits you to offer its warm and friendly
hospitality. If your choice is comfort and service at a reasonable      
price, come and let us show you our care for your simple and
most extravagant desires.</p>
                        <div class="row">
                            <div class="col-md-6">
                                <h4>
                                    <i class="far fa-star"></i>Fast Wifi</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                                    <i class="far fa-star"></i>
                                    Airport Drop and Pickup</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                                    <i class="far fa-star"></i>Daily housekeeping</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                                    <i class="far fa-star"></i>
                                    Air conditioning facilities</h4>
                            </div>
                            <div class="col-md-6">
                                <h4>
                                    <i class="far fa-star"></i>Smoking Areas</h4>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
</div>);
}

export default Abt;