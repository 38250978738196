import React from "react";

function Popup(){
    return ( <div>
   <div id="welcome-user" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content welcome-box">
      <div class="modal-header bb-0">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body welcome-box-body">
      Welcome to Hotel Janakpur Inn, a boutique hotel in Janakpur.<br></br> Our cozy rooms, delicious dining, and prime location make your stay unforgettable.
      </div>
    </div>
  </div>
</div>
  </div>);
}

export default Popup;