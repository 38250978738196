import React from "react";

function AboutUs(){
    return( <div>
    <section class="about" id="about">
            <div class="container">
                <div class="heading">
                    <h2 style={{"textAlign":"center"}}>How to Reach
                        <span> Us</span></h2>
                    <p>Hotel Janakpurinn is fifteen minutes drive from Janakpur airport and railway station. It is one minute walking distance from Ramanand Chowk, Bus stand. Janakpurdham is well connected by airways, railways and bus. Janakpur airport has around 15 daily flights from Kathmandu. 
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                    
                    <h3>Airways </h3>
                    <p>The nearest airport in India is Darbhanga airport which is 80 km from Janakpur. It is well connected by public bus and private cars. The international airport in Nepal is Tribhuvan International airport (TIA), Kathmandu. TIA is 230 km from Janakpur. There are around 12-15 daily flights between TIA and Janakpurdham airport and the total flight time is 25 minutes.</p>
                    <br></br><br></br><img style={{"maxHeight":"150px", "minHeight":"150px", "width":"80%"}} src="https://i.ibb.co/tQ543Td/Ep-SO8-D-UYAE7z7-M.jpg" alt="guests1" class="img-fluid"></img> 
                  

                    </div>

                    <div class="col-lg-4">
                    
                    <h3>Railways </h3>
                    <p>The nearest railway station in India is Jaynagar which is 40km from hotel Janakpurinn. The train timing from Jaynagar to Janakpurdham is 8:30, 15:00 and 18:00. The train timing from Janakpur to Jaynagar is 8:35, 11:30 and 16:30. The second nearest railway station in India is Sitamarhi railway station which is 52km away from Hotel janakpurinn. Sitamarhi is well connected by bus and private car. The third nearest railway station is Darbhanga railway which is 80km away from Janakpur</p>
                    <img style={{"maxHeight":"150px", "width":"80%"}} src="https://www.newbusinessage.com/img/news/20220823112051_1661169484.NepaliRailway.jpg" alt="guests1" class="img-fluid"></img>
                    </div>


                    <div class="col-lg-4">
                        <h3>Roadways</h3>
                        <p>From Kathmandu, there are regular buses that travel to Janakpur from New Bus Station or Kalanki Bus Stand. The total journey takes about eight hours with stops for meals. The same journey can also be covered by hiring a private cab for a relatively more comfortable travel experience. From India, the cities of Jayanagar and Sitamarhi are located only 30 and 45 km respectively from Janakpur in Nepal.</p>
                        
                        <img style={{"maxHeight":"150px", "width":"80%"}} src="https://4.bp.blogspot.com/-4OzydCpeQTA/WUn-waE-n3I/AAAAAAAAACE/JBPru9bA5uEZ2KnLr5dQ9moCj12D-BsbgCLcBGAs/s1600/Kathmandu-to-Janakpur-bus.jpg" alt="guests1" class="img-fluid"></img>
                    

                    </div>
                </div>
                <br></br>
                <h3 style={{"textAlign":"center"}}>Our travel services</h3>
                <br></br>
                <div class="row">
                            <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>Janakpur airport pickup or drop</h4>
                                    <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Alto (4-seater) Price: Nrs. 800</h4>
                            </div>
                            </div>
                            <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>
                                    Sitamarhi railway station drop</h4>
                                    <div class="col-lg-12">
                                    <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Alto (4-seater) price: Nrs. 5,500</h4>
                            </div>
                            <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Scorpio (7-seater) price: Nrs. 7,000</h4>
                            </div>
                            </div>
                            </div>
                            <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>Jaynagar railway station drop</h4>
                                    <div class="col-lg-12">
                                    <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Alto (4-seater) price: Nrs. 4,000</h4>
                            </div>
                            <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Scorpio (7-seater) price: Nrs. 6,000</h4>
                            </div>
                            </div>
                            </div>

                            <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>Janakpur to Kathmandu by road</h4>
                                    <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Alto (4-seater) price: Nrs. 13,000</h4>
                            </div>
                            <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Scorpio (7-seater) price: Nrs. 18,000</h4>
                            </div>
                            </div>

                            <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>Janakur to Darbhanga airport/railway station</h4>
                                    <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Alto (4-seater) price: Nrs. 6,000</h4>
                            </div>
                            <div class="col-lg-12">
                                <h4>
                                    <i class="far fa-circle" style={{"fontSize":"10px"}}></i>Scorpio (7-seater) price: Nrs. 8,000</h4>
                            </div>
                            </div>

                            
                            {/* <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>
                                    Air conditioning facilities</h4>
                            </div> */}
                            
                            {/* <div class="col-lg-4">
                                <h4>
                                    <i class="far fa-star"></i>
                                    Gym/Fitness</h4>
                            </div> */}
                        </div>
            </div>

            
        </section>
</div>);
}

export default AboutUs;