import React from "react";
import swal from '@sweetalert/with-react'


function Success(){

  

  swal("Your payment has been received", "Your booking details has been sent to your email", "success").then((confirmed) => {
    if(confirmed){
      window.location.href="http://janakpurinn.com";
    }
    }
    )
    return( <div>
    
    </div>);
}

export default Success;