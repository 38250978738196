import React, { useEffect, useState, useRef } from 'react';
import Confetti from 'react-confetti';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function Celebration() {
  const [showConfetti, setShowConfetti] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        setShowConfetti(false);
        setShowModal(false);
      }
    }, 20000);

    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div>
      {showConfetti && <Confetti numberOfPieces={600} width={window.innerWidth} height={window.innerHeight} style={{ position: 'absolute', top: 0, left: 0, zIndex: 100 }} />}
      
      <Modal show={showModal} onHide={handleClose} style={{ marginTop: '50px' }}>
  <Modal.Header closeButton style={{ backgroundColor: '#FDFCFB', borderBottom: '1px solid #C0C2C9' }}>
    <Modal.Title style={{ color: '#0A3A82', fontFamily: 'Georgia, serif', fontSize: '24px' }}>Happy 2nd Anniversary!</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ color: '#333333', fontFamily: 'Georgia, serif', fontSize: '16px' }}>
    <p>We extend our <span style={{ color: '#FF6B6B' }}>heartfelt gratitude</span> and warm thanks to all our guests and hotel family as we celebrate our second anniversary! Your support motivates us to uphold our quality of service as we look forward to serving you for many more years.</p>
  </Modal.Body>
  <Modal.Footer style={{ borderTop: '1px solid #C0C2C9', backgroundColor: '#FDFCFB' }}>
    <Button variant="secondary" onClick={handleClose} style={{ backgroundColor: '#0A3A82', color: '#FDFCFB', fontSize: '14px', padding: '5px 10px' }}>Close</Button>
  </Modal.Footer>
</Modal>

    </div>
  );
}

export default Celebration;
